import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import Home from "./HomeComponent.js";
import About from "./AboutComponent.js";
import Contact from "./ContactComponent.js";
import Footer from "./FooterComponent.js";
import NotFound from "./NotFoundComponent.js";
import { ENGLISH } from "../languages/english.js";
import { ROMANA } from "../languages/romana.js";
import { ITALIANO } from "../languages/italiano.js";
import { ESPANOL } from "../languages/espanol.js";
import { KARTULI } from "../languages/kartuli.js";
import { AZERBAYCAN } from "../languages/azerbaycan.js";

import { NavLink, Link } from "react-router-dom";

import { Navbar, Nav, NavItem } from "reactstrap";
import { TransitionGroup, CSSTransition } from "react-transition-group";

let initialLang = "";
let initialActiveLang = "";
let initialLocation = "";
let homeAddr = "";
let firstload = true;

const initalLocation = window.location.href;
const initLoc = window.location.pathname;

if (initalLocation.indexOf("/ro/") > -1) {
  initialLang = ROMANA[0];
  initialActiveLang = "ro";
} else if (initalLocation.indexOf("/en/") > -1) {
  initialLang = ENGLISH[0];
  initialActiveLang = "en";
} else if (initalLocation.indexOf("/it/") > -1) {
  initialLang = ITALIANO[0];
  initialActiveLang = "it";
} else if (initalLocation.indexOf("/es/") > -1) {
  initialLang = ESPANOL[0];
  initialActiveLang = "es";
} else if (initalLocation.indexOf("/ka/") > -1) {
  initialLang = KARTULI[0];
  initialActiveLang = "ka";
} else if (initalLocation.indexOf("/az/") > -1) {
  initialLang = AZERBAYCAN[0];
  initialActiveLang = "az";
} else {
  initialLang = ENGLISH[0];
  initialActiveLang = "en";
}

if (
  initalLocation.indexOf(ENGLISH[0].menu.about.path) > -1 ||
  initalLocation.indexOf(ROMANA[0].menu.about.path) > -1 ||
  initalLocation.indexOf(ITALIANO[0].menu.about.path) > -1 ||
  initalLocation.indexOf(ESPANOL[0].menu.about.path) > -1 ||
  initalLocation.indexOf(KARTULI[0].menu.about.path) > -1 ||
  initalLocation.indexOf(AZERBAYCAN[0].menu.about.path) > -1
) {
  initialLocation = "about";
  // } else if (
  //   initalLocation.indexOf(ENGLISH[0].menu.projects.path) > -1 ||
  //   initalLocation.indexOf(ROMANA[0].menu.projects.path) > -1 ||
  //   initalLocation.indexOf(ITALIANO[0].menu.projects.path) > -1 ||
  //   initalLocation.indexOf(ESPANOL[0].menu.projects.path) > -1 ||
  //   initalLocation.indexOf(KARTULI[0].menu.projects.path) > -1 ||
  //   initalLocation.indexOf(AZERBAYCAN[0].menu.projects.path) > -1
  // ) {
  //   initialLocation = "projects";
} else if (
  initalLocation.indexOf(ENGLISH[0].menu.contact.path) > -1 ||
  initalLocation.indexOf(ROMANA[0].menu.contact.path) > -1 ||
  initalLocation.indexOf(ITALIANO[0].menu.contact.path) > -1 ||
  initalLocation.indexOf(ESPANOL[0].menu.contact.path) > -1 ||
  initalLocation.indexOf(KARTULI[0].menu.contact.path) > -1 ||
  initalLocation.indexOf(AZERBAYCAN[0].menu.contact.path) > -1
) {
  initialLocation = "contact";
} else if (
  initalLocation.indexOf(ENGLISH[0].menu.home.path) > -1 ||
  initalLocation.indexOf(ROMANA[0].menu.home.path) > -1 ||
  initalLocation.indexOf(ITALIANO[0].menu.home.path) > -1 ||
  initalLocation.indexOf(ESPANOL[0].menu.home.path) > -1 ||
  initalLocation.indexOf(KARTULI[0].menu.home.path) > -1 ||
  initalLocation.indexOf(AZERBAYCAN[0].menu.home.path) > -1 ||
  initLoc === "/"
) {
  initialLocation = "home";
} else {
  initialLocation = "notfound";
}

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: initialLang,
      active: initialActiveLang,
      current: initialLocation,
      isNavOpen: false,
      isModalOpen: false,
    };
  }

  changeLanguage = (lang, short) => {
    this.setState({
      language: lang,
      active: short,
    });
  };

  setCurrentPage = (page) => {
    this.setState({
      current: page,
    });

    firstload = false;
  };

  toggleModal = () => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
    });
  };

  toggleNav = () => {
    this.setState({
      isNavOpen: !this.state.isNavOpen,
    });
  };

  componentDidMount() {}

  render() {
    const say = this.state.language;

    if (initLoc === "/" && firstload) {
      homeAddr = "";
    } else {
      homeAddr = say.menu.home.path;
    }

    return (
      <div>
        <React.Fragment>
          <Navbar dark expand="md" className="bg-primary navbar-right">
            <div className="container navbar-contain">
              <Link
                to={say.menu.home.path}
                className="mr-auto  navbar-left"
                onClick={() => this.setCurrentPage("home")}
              >
                <img
                  src="/assets/images/ionelcutsm3.png"
                  alt="logo"
                  className="weblogo"
                  onClick={() => this.setCurrentPage("home")}
                />
              </Link>

              <Nav navbar className="header-nav">
                <NavItem>
                  <NavLink
                    className="nav-link"
                    to={homeAddr}
                    onClick={() => this.setCurrentPage("home")}
                  >
                    <span onClick={() => this.setCurrentPage("home")}>
                      <span className="fa fa-home" />
                      <br />
                      <span
                        className="descript-icon"
                        onClick={() => this.setCurrentPage("home")}
                      >
                        {say.menu.home.value}
                      </span>
                    </span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className="nav-link"
                    to={say.menu.about.path}
                    onClick={() => this.setCurrentPage("about")}
                  >
                    <span onClick={() => this.setCurrentPage("about")}>
                      <span className="fa fa-user" />
                      <br />
                      <span
                        className="descript-icon"
                        onClick={() => this.setCurrentPage("about")}
                      >
                        {say.menu.about.value}
                      </span>
                    </span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className="nav-link"
                    to={say.menu.contact.path}
                    onClick={() => this.setCurrentPage("contact")}
                  >
                    <span onClick={() => this.setCurrentPage("contact")}>
                      <span className="fa fa-envelope" />
                      <br />
                      <span
                        className="descript-icon"
                        onClick={() => this.setCurrentPage("contact")}
                      >
                        {say.menu.contact.value}
                      </span>
                    </span>
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </Navbar>

          <div className="languages">
            <div className="container">
              <div className="row row-header language-menu">
                <div className="col-12 ">
                  <Link
                    to={ENGLISH[0].menu[this.state.current].path}
                    className="mr-auto  navbar-left"
                  >
                    <img
                      src="/assets/images/en.jpg"
                      onClick={() => this.changeLanguage(ENGLISH[0], "en")}
                      className={this.state.active === "en" ? "active" : ""}
                      alt="en"
                    />
                  </Link>
                  <Link
                    to={ESPANOL[0].menu[this.state.current].path}
                    className="mr-auto  navbar-left"
                  >
                    <img
                      src="/assets/images/es.png"
                      onClick={() => this.changeLanguage(ESPANOL[0], "es")}
                      className={this.state.active === "es" ? "active" : ""}
                      alt="es"
                    />
                  </Link>
                  <Link
                    to={ITALIANO[0].menu[this.state.current].path}
                    className="mr-auto  navbar-left"
                  >
                    <img
                      src="/assets/images/it.png"
                      onClick={() => this.changeLanguage(ITALIANO[0], "it")}
                      className={this.state.active === "it" ? "active" : ""}
                      alt="it"
                    />
                  </Link>
                  <Link
                    to={ROMANA[0].menu[this.state.current].path}
                    className="mr-auto  navbar-left"
                  >
                    <img
                      src="/assets/images/ro.png"
                      onClick={() => this.changeLanguage(ROMANA[0], "ro")}
                      className={this.state.active === "ro" ? "active" : ""}
                      alt="ro"
                    />
                  </Link>
                  <Link
                    to={AZERBAYCAN[0].menu[this.state.current].path}
                    className="mr-auto  navbar-left"
                  >
                    <img
                      src="/assets/images/az.png"
                      onClick={() => this.changeLanguage(AZERBAYCAN[0], "az")}
                      className={this.state.active === "az" ? "active" : ""}
                      alt="az"
                    />
                  </Link>
                  <Link
                    to={KARTULI[0].menu[this.state.current].path}
                    className="mr-auto  navbar-left"
                  >
                    <img
                      src="/assets/images/ka.png"
                      onClick={() => this.changeLanguage(KARTULI[0], "ka")}
                      className={this.state.active === "ka" ? "active" : ""}
                      alt="ka"
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>

        <TransitionGroup>
          <CSSTransition
            classNames="page"
            timeout={300}
            key={say.menu[this.state.current].path}
          >
            <Switch>
              <Route
                exact
                path={say.menu.home.path}
                component={() => <Home language={this.state.language} />}
              />

              <Route
                exact
                path={say.menu.about.path}
                component={() => <About language={this.state.language} />}
              />

              <Route
                exact
                path={say.menu.contact.path}
                component={() => <Contact language={this.state.language} />}
              />

              <Route
                exact
                path="/"
                component={() => <Home language={this.state.language} />}
              />
              <Route
                component={() => <NotFound language={this.state.language} />}
              />
            </Switch>
          </CSSTransition>
        </TransitionGroup>

        <Footer language={this.state.language} />
      </div>
    );
  }
}

export default Main;
