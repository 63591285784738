import React, { Component } from "react";

import { Helmet } from "react-helmet";

import { Map, Marker, TileLayer } from "react-leaflet";

import L from "leaflet";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import { Fade } from "react-animation-components";

let DefaultIcon = L.icon({
  iconUrl: "/assets/images/code.png",
  shadowUrl: iconShadow,
});

L.Marker.prototype.options.icon = DefaultIcon;
//bh
// const position = [46.7235, 26.6965];
// const positionMarker = [46.7257, 26.6965];
//rm
// const position = [41.9013, 12.50172];
// const positionMarker = [41.9013, 12.50172];
const position = [45.9432, 24.9668];
const positionMarker = [45.9432, 24.9668];
const map = (
  <Map center={position} zoom={6} maxZoom="6" attributionControl={false}>
    <TileLayer
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      attribution=" "
    />
    <Marker position={positionMarker} />
  </Map>
);

class Contact extends Component {
  render() {
    const say = this.props.language;
    const currUrl = "http:/ionelcostel.eu" + say.menu.contact.path;

    return (
      <div className="body">
        <Helmet htmlAttributes={{ lang: say.lang }}>
          <meta charSet="utf-8" />
          <title>{say.menu.contact.value + " - " + say.title}</title>
          <link rel="canonical" href={currUrl} />
        </Helmet>

        <section className="mt-5 mb-5  ">
          <div className="container ">
            <div className="row text-center big-contain">
              <div className="col-sm-6 align-self-center">
                <h3>{say.footer.message}</h3>
                <a href="mailto:ionelcostelpopescu251@gmail.com">
                  {" "}
                  ionelcostelpopescu251 @ gmail.com
                </a>
              </div>
              <div className="col-sm-6 align-self-center">
                <h3>{say.footer.find}</h3>
                <div>
                  <a
                    href="https://www.linkedin.com/in/ionelcostel"
                    className="sociallink"
                  >
                    <i className="fa fa-linkedin"> </i> Linkedin
                  </a>
                </div>
                <a href="https://codepen.io/ionelcp/" className="sociallink">
                  <i className="fa fa-codepen" /> CodePen
                </a>
              </div>
            </div>
          </div>
        </section>

        <Fade in>
          <section>
            <div id="contact-map">{map}</div>
          </section>
        </Fade>

        <section>
          <div className="container mt-5">
            <div className="row">
              <img
                className="align-self-center col-xs-12 offset-md-3 col-sm-6"
                src="/assets/images/web-developer-bacau.png"
                alt="web developer web design realizare site-uri bacau"
              />
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Contact;
