export const ROMANA = [
  {
    home: {
      title: "Dezvoltare web",
      description: "Web design",
      rwd: "Responsive Web Design (RWD)",
      tech: "Tehnologii",
    },
    about: {
      title: "Ionel Costel Popescu",
      description: "Web Developer",
      born: "Născut: 25/11/1991, Buhuși, România",
      currentCity: "Locație actuală: România",
      do:
        "Folosesc HTML5, CSS3, Javascript, PHP și Wordpress pentru a crea website-uri cu un Design Responsive. ",
      contact: "",
      video: "http://ionelcostel.eu/assets/video/demo_ro.mp4",
    },
    lang: "ro",
    title: "Ionel Costel Popescu - Web developer Bacau, Romania",
    footer: {
      message: "Trimite-mi un mesaj:",
      find: "Mă găsești pe:",
      copyright: "© Copyright Ionel Costel Popescu 2018",
    },
    menu: {
      home: {
        path: "/ro/acasa",
        value: "Acasa",
      },
      about: {
        path: "/ro/despre",
        value: "Despre",
      },
      contact: {
        path: "/ro/contact",
        value: "Contact",
      },
      notfound: {
        path: "/ro/404",
        value: "404 Eroare",
      },
    },
  },
];
