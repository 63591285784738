export const KARTULI = [
  {
    home: {
      title: "ვებგანვითარება",
      description: "ვებ დიზაინი",
      rwd: "Responsive web design (მოქნილი) ვებ-საიტის დიზაინის (RWD)",
      tech: "ტექნოლოგიები",
    },
    about: {
      title: "იონელ კოსტელ პოპესკუ",
      description: "ვებ დეველოპერი",
      born: "დაიბადა: 25/11/1991, ბუხუში,ბაკეუ, რუმინეთი",
      currentCity: "ახლანდელი ადგილსამყოფელი: რუმინეთი",
      do:
        "მე ვიყენებ HTML5, CSS3, ჯავასკრიპტი, PHP და Wordpress, Responsive საიტების შექმნა. ",
      contact: "",
      video: "http://ionelcostel.eu/assets/video/demo_ka.mp4",
    },
    lang: "ka",
    title: "Ionel Costel Popescu - Web developer Bacau, Romania",
    footer: {
      message: "გამომიგზავნეთ მესიჯი:",
      find: "ჩემი გვერდები:",
      copyright: "© იონელ კოსტელ პოპესკუ 2018 ყველა უფლება დაცულია",
    },
    menu: {
      home: {
        path: "/ka/mtavari",
        value: "მთავარი",
      },
      about: {
        path: "/ka/shesakheb",
        value: "შესახებ",
      },
      contact: {
        path: "/ka/kontaqti",
        value: "კონტაქტი",
      },
      notfound: {
        path: "/ka/404",
        value: "404 შეცდომა",
      },
    },
  },
];
