export const ESPANOL = [
  {
    home: {
      title: "Desarrollo Web",
      description: "Diseño Web",
      rwd: "Diseño web adaptable (RWD)",
      tech: "Tecnologías",
    },
    about: {
      title: "Ionel Costel Popescu",
      description: "Desarrollador Web",
      born: "Nacido: 25/11/1991, Buhuși, Bacău, Rumania",
      currentCity: "Ubicación actual: Rumania",
      do:
        "Yo estoy utilizando HTML5, CSS3, Javascript, PHP y Wordpress para crear sitios con Diseño web adaptable. ",
      contact: "",
      video: "http://ionelcostel.eu/assets/video/demo_es.mp4",
    },
    lang: "es",
    title: "Ionel Costel Popescu - Web developer Bacau, Romania",
    footer: {
      message: "Envíeme un mensaje:",
      find: "Me puedes encontrar en:",
      copyright: "© Copyright Ionel Costel Popescu 2018",
    },
    menu: {
      home: {
        path: "/es/inicio",
        value: "Inicio",
      },
      about: {
        path: "/es/acerca-de-mi",
        value: "Acerca de mi",
      },
      contact: {
        path: "/es/contacto",
        value: "Contacto",
      },
      notfound: {
        path: "/es/404",
        value: "404 Error",
      },
    },
  },
];
