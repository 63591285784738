export const ITALIANO = [
  {
    home: {
      title: "Sviluppo Web",
      description: "Web design",
      rwd: "Design responsivo (RWD)",
      tech: "Tecnologie",
    },
    about: {
      title: "Ionel Costel Popescu",
      description: "Web Developer",
      born: "Nato: il 25/11/1991, a Buhuși, Bacău, Romania",
      currentCity: "Città attuale: Romania",
      do:
        "Io utilizzo HTML5, CSS3, Javascript, PHP e Wordpress per creare dei siti con un Design responsivo.",
      contact: "",
      video: "http://ionelcostel.eu/assets/video/demo_it.mp4",
    },
    lang: "it",
    title: "Ionel Costel Popescu - Web developer Bacau, Romania",
    footer: {
      message: "Mandami un messaggio:",
      find: "Mi puoi trovare su:",
      copyright: "© Copyright Ionel Costel Popescu 2018",
    },
    menu: {
      home: {
        path: "/it/inizio",
        value: "Inizio",
      },
      about: {
        path: "/it/chi-sono",
        value: "Chi sono",
      },
      contact: {
        path: "/it/contatti",
        value: "Contatti",
      },
      notfound: {
        path: "/it/404",
        value: "404 Errore",
      },
    },
  },
];
