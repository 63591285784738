import React, { Component } from "react";
import { Helmet } from "react-helmet";

import { Fade } from "react-animation-components";
import ReactPlayer from "react-player";

class About extends Component {
  render() {
    const say = this.props.language;
    const currUrl = "http:/ionelcostel.eu" + say.menu.about.path;
    return (
      <div className="body">
        <Helmet htmlAttributes={{ lang: say.lang }}>
          <meta charSet="utf-8" />
          <title>{say.menu.about.value + " - " + say.title}</title>
          <link rel="canonical" href={currUrl} />
        </Helmet>

        <section className="mt-5 ">
          <div className="container">
            <div className="row">
              <img
                className="align-self-center col-xs-12 col-sm-6"
                src="/assets/images/web-developer-bacau.png"
                alt="web developer web design realizare site-uri bacau"
              />
              <div className="align-self-center col-xs-12 col-sm-6 text-center">
                <h2>{say.about.title}</h2>
                <h3>{say.about.description}</h3>
                <p>{say.about.currentCity}</p>
                <p>{say.about.born}</p>
                <p>{say.about.do}</p>
              </div>
            </div>
          </div>
        </section>

        <Fade in>
          <section className="mt-5 pb-3 pt-3 languages">
            <div className="container">
              <div className="row">
                <div className="col text-center pb-5 pt-5">
                  <ReactPlayer
                    width="100%"
                    height="100%"
                    url={say.about.video}
                    playing={false}
                    loop={false}
                    muted={true}
                    volume={0}
                    controls={true}
                  />
                </div>
              </div>
            </div>
          </section>
        </Fade>
      </div>
    );
  }
}

export default About;
