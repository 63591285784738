export const ENGLISH = [
  {
    home: {
      title: "Web Development",
      description: "Web Design",
      rwd: "Responsive web design (RWD)",
      tech: "Technologies",
    },
    about: {
      title: "Ionel Costel Popescu",
      description: "Web Developer",
      born: "Born: 25/11/1991, Buhuși, Bacău, Romania",
      currentCity: "Current location: Romania",
      do:
        "I use HTML5, CSS3, Javascript, PHP and Wordpress to create Responsive websites.",
      contact: "",
      video: "http://ionelcostel.eu/assets/video/demo_en.mp4",
    },
    lang: "en",
    title: "Ionel Costel Popescu - Web developer Bacau, Romania",
    footer: {
      message: "Send me a message:",
      find: "You can find me on:",
      copyright: "© Copyright Ionel Costel Popescu 2018",
    },
    menu: {
      home: {
        path: "/en/home",
        value: "Home",
      },
      about: {
        path: "/en/about",
        value: "About",
      },
      contact: {
        path: "/en/contact",
        value: "Contact",
      },
      notfound: {
        path: "/en/404",
        value: "404 Error",
      },
    },
  },
];
