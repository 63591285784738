import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Slider from "react-slick";

import { Fade } from "react-animation-components";

const NextArrow = props => {
  const { onClick } = props;
  return (
    <div onClick={onClick} className="myarr arr-right">
      <i className="fa fa-chevron-right"></i>
    </div>
  );
};

const PrevArrow = props => {
  const { onClick } = props;
  return (
    <div onClick={onClick} className="myarr arr-left">
      <i className="fa fa-chevron-left"></i>
    </div>
  );
};

class Home extends Component {
  render() {
    const say = this.props.language;
    var settings = {
      arrows: true,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      dots: false,
      infinite: true,
      speed: 300,
      slidesToShow: 5,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true
          }
        }
      ]
    };
    const currUrl = "http:/ionelcostel.eu" + say.menu.home.path;
    return (
      <div className="body">
        <Helmet htmlAttributes={{ lang: say.lang }}>
          <meta charSet="utf-8" />
          <title>{say.menu.home.value + " - " + say.title}</title>
          <link rel="canonical" href={currUrl} />
        </Helmet>

        <section className="mt-5">
          <div className="container">
            <div className="row">
              <div className="align-self-center col-xs-12 col-sm-6 text-center d-none d-sm-block">
                <img
                  className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center"
                  src="/assets/images/ionelblue3.png"
                  alt="web developer web design realizare site-uri bacau"
                />
              </div>

              <div className="align-self-center col-xs-12 col-sm-6 text-center">
                <h2>{say.home.title}</h2>
                <h3>{say.home.description}</h3>
                <img
                  className="align-self-center col-xs-12 col-sm-9"
                  src="/assets/images/laptop-sm.png"
                  alt="responsive web design realizare site-uri bacau"
                />
              </div>
            </div>
          </div>
        </section>

        <Fade in>
          <section className="mt-5 mb-5 pt-5 pb-5 bg-primary text-white">
            <div className="container">
              <div className="row">
                <div className="col text-center mb-3 pb-3">
                  <h1>{say.home.tech}</h1>
                </div>
              </div>
              <div className="row">
                <div className="col slide-techs text-center">
                  <Slider {...settings}>
                    <div className="containers-logos">
                      <img
                        className="align-self-center  "
                        src="/assets/images/jquery.png"
                        alt="jquery responsive web design realizare site-uri bacau"
                      />
                    </div>
                    <div className="containers-logos">
                      <img
                        className="align-self-center"
                        src="/assets/images/React_logo.png"
                        alt="React_logo.png responsive web design realizare site-uri bacau"
                      />
                    </div>
                    <div className="containers-logos">
                      <img
                        className="align-self-center"
                        src="/assets/images/redux.png"
                        alt="redux.png responsive web design realizare site-uri bacau"
                      />
                    </div>
                    <div className="containers-logos">
                      <img
                        className="align-self-center"
                        src="/assets/images/angular.png"
                        alt="angular.png responsive web design realizare site-uri bacau"
                      />
                    </div>
                    <div className="containers-logos">
                      <img
                        className="align-self-center"
                        src="/assets/images/typescript.png"
                        alt="typescript.png responsive web design realizare site-uri bacau"
                      />
                    </div>
                    <div className="containers-logos">
                      <img
                        className="align-self-center"
                        src="/assets/images/php.png"
                        alt="php responsive web design realizare site-uri bacau"
                      />
                    </div>
                    <div className="containers-logos">
                      <img
                        className="align-self-center"
                        src="/assets/images/mysql.png"
                        alt="mysql responsive web design realizare site-uri bacau"
                      />
                    </div>
                    <div className="containers-logos">
                      <img
                        className="align-self-center"
                        src="/assets/images/wordpress.png"
                        alt="wordpress responsive web design realizare site-uri bacau"
                      />
                    </div>
                    <div className="containers-logos">
                      <img
                        className="align-self-center  "
                        src="/assets/images/AJAX.png"
                        alt="AJAX responsive web design realizare site-uri bacau"
                      />
                    </div>
                    <div className="containers-logos">
                      <img
                        className="align-self-center"
                        src="/assets/images/leaflet.png"
                        alt="leaflet responsive web design realizare site-uri bacau"
                      />
                    </div>
                    <div className="containers-logos">
                      <img
                        className="align-self-center"
                        src="/assets/images/bootstrap.png"
                        alt="bootstrap responsive web design realizare site-uri bacau"
                      />
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </section>
        </Fade>

        <Fade in>
          <section className="mb-5 pb-5">
            <div className="container">
              <div className="row">
                <div className="align-self-center col-xs-12 col-sm-6 text-center">
                  <h2>{say.home.rwd}</h2>
                </div>
                <img
                  className="align-self-center col-xs-12 col-sm-6"
                  src="/assets/images/responsive1.png"
                  alt="responsive web design realizare site-uri bacau"
                />
              </div>
            </div>
          </section>
        </Fade>
      </div>
    );
  }
}

export default Home;
