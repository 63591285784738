export const AZERBAYCAN = [
  {
    home: {
      title: "Veb İnkişafı",
      description: "Veb-dizayn",
      rwd: "Responsive(Həssas) Web Dizayn (RWD)",
      tech: "Texnologiyalar",
    },
    about: {
      title: "Ionel Costel Popescu",
      description: "Web Developer",
      born: "Doğulduğum yer və tarix : Buhuși, Bacău, Rumıniya, 25/11/1991",
      currentCity: "Hazırki məkan: Rumıniya",
      do:
        "HTML5, CSS3, Javascript, PHP və Wordpress`dən istifadə edərək responsiv vebsaytlar hazırlayıram. ",
      contact: "",
      video: "http://ionelcostel.eu/assets/video/demo_az.mp4",
    },
    lang: "az",
    title: "Ionel Costel Popescu - Web developer Bacau, Romania",
    footer: {
      message: "Mənə bir xəbər yolla:",
      find: "Məni tapa bilərsiniz:",
      copyright: "© Copyright Ionel Costel Popescu 2018",
    },
    menu: {
      home: {
        path: "/az/bas-sehife",
        value: "Baş səhifə",
      },
      about: {
        path: "/az/haqqimda",
        value: "Haqqımda",
      },
      contact: {
        path: "/az/elaqe",
        value: "Əlaqə",
      },
      notfound: {
        path: "/az/404",
        value: "404 Səhv",
      },
    },
  },
];
