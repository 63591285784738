import React, {Component} from 'react';

import {Helmet} from "react-helmet";

class NotFound extends Component {

    render(){
        const say = this.props.language;
        const currUrl = "http:/ionelcostel.eu" + say.menu.contact.path;
        return(
            <div className="body">
            <Helmet htmlAttributes={{ lang : say.lang }}>
                <meta charSet="utf-8" />
                <title>{say.menu.notfound.value + ' - ' + say.title}</title>
                <link rel="canonical" href={currUrl} />
            </Helmet>
                <div className="container">
                  <div className="row">
                    <div className="col text-center">
                      <h1>{ say.menu.notfound.value}</h1>
                    </div>
                  </div>
                </div>
            </div>
        );
    }

}

export default NotFound;
