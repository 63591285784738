import React from "react";
const Footer = props => {
  return (
    <section className="bg-primary text-white footer pt-3 pb-3">
      <div className="container mt-3 mb-3">
        <div className="row text-center">
          <div className="col-sm-6">
            <h3>{props.language.footer.message}</h3>
            <a href="mailto:ionelcostelpopescu251@gmail.com">
              {" "}
              ionelcostelpopescu251 @ gmail.com
            </a>
          </div>
          <div className="col-sm-6">
            <h3>{props.language.footer.find}</h3>
            <div>
              <a
                href="https://www.linkedin.com/in/ionelcostel"
                className="sociallink"
              >
                <i className="fa fa-linkedin"> </i> Linkedin
              </a>
            </div>
            <a href="https://codepen.io/ionelcp/" className="sociallink">
              <i className="fa fa-codepen" /> CodePen
            </a>
          </div>
        </div>

        <div className="row text-center mt-3">
          <div className="col">
            {props.language.footer.copyright + " - " + new Date().getFullYear()}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
